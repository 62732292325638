var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_vm._m(0)]),_c('hr'),_c('el-tabs',[_c('el-tab-pane',{attrs:{"label":"Active"}},[_c('v-client-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.load),expression:"load"}],attrs:{"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"logo",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('img',{attrs:{"src":_vm.baseServerUrl +'storage/'+row.logo,"width":"100"}})])}},{key:"suspended_for_nonpayment",fn:function(props){return _c('div',{},[(_vm.checkRole(['super']))?_c('div',[_c('el-select',{staticStyle:{"width":"100%"},on:{"input":function($event){return _vm.toggleSuspensionStatus(props, $event)}},model:{value:(props.row.suspended_for_nonpayment),callback:function ($$v) {_vm.$set(props.row, "suspended_for_nonpayment", $$v)},expression:"props.row.suspended_for_nonpayment"}},[_c('el-option',{attrs:{"label":"Active","value":0}}),_c('el-option',{attrs:{"label":"Suspended","value":1}})],1)],1):_c('div',[_vm._v(" "+_vm._s((props.row.suspended_for_nonpayment === 0) ? 'Active' : 'Suspended')+" ")])])}},{key:"created_at",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(_vm._s(_vm.moment(row.created_at).format('MMM Do YYYY'))+" ")])}},{key:"action",fn:function(props){return _c('div',{},[_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('View Details'),expression:"'View Details'",modifiers:{"hover":true,"right":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"primary"}},[_c('router-link',{staticStyle:{"color":"#fff"},attrs:{"to":{name: 'schoolDetails', params: {id: props.row.id}}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1)],1)],1),(_vm.checkPermission(['can manage schools']))?_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Login as admin of ' + props.row.name),expression:"'Login as admin of ' + props.row.name",modifiers:{"hover":true,"right":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"dark"},on:{"click":function($event){return _vm.loginToASchool(props.row)}}},[_c('feather-icon',{attrs:{"icon":"KeyIcon"}})],1)],1):_vm._e()])}}]),model:{value:(_vm.activeSchools),callback:function ($$v) {_vm.activeSchools=$$v},expression:"activeSchools"}})],1),_c('el-tab-pane',{attrs:{"label":"Suspended"}},[_c('v-client-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.load),expression:"load"}],attrs:{"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"logo",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('img',{attrs:{"src":_vm.baseServerUrl +'storage/'+row.logo,"width":"100"}})])}},{key:"suspended_for_nonpayment",fn:function(props){return _c('div',{},[(_vm.checkRole(['super']))?_c('div',[_c('el-select',{staticStyle:{"width":"100%"},on:{"input":function($event){return _vm.toggleSuspensionStatus(props, $event)}},model:{value:(props.row.suspended_for_nonpayment),callback:function ($$v) {_vm.$set(props.row, "suspended_for_nonpayment", $$v)},expression:"props.row.suspended_for_nonpayment"}},[_c('el-option',{attrs:{"label":"Active","value":0}}),_c('el-option',{attrs:{"label":"Suspended","value":1}})],1)],1):_c('div',[_vm._v(" "+_vm._s((props.row.suspended_for_nonpayment === 0) ? 'Active' : 'Suspended')+" ")])])}},{key:"created_at",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(_vm._s(_vm.moment(row.created_at).format('MMM Do YYYY'))+" ")])}},{key:"action",fn:function(props){return _c('div',{},[_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('View Details'),expression:"'View Details'",modifiers:{"hover":true,"right":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"primary"}},[_c('router-link',{staticStyle:{"color":"#fff"},attrs:{"to":{name: 'schoolDetails', params: {id: props.row.id}}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1)],1)],1),(_vm.checkPermission(['can manage schools']))?_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Login as admin of ' + props.row.name),expression:"'Login as admin of ' + props.row.name",modifiers:{"hover":true,"right":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"dark"},on:{"click":function($event){return _vm.loginToASchool(props.row)}}},[_c('feather-icon',{attrs:{"icon":"KeyIcon"}})],1)],1):_vm._e()])}}]),model:{value:(_vm.suspendedSchools),callback:function ($$v) {_vm.suspendedSchools=$$v},expression:"suspendedSchools"}})],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('strong',[_vm._v("List of Approved Schools")])])}]

export { render, staticRenderFns }